//Global colors
$white: #fff;
$black: #212121;
$blueText: #000099;
$buttonsColor: #ca001b;
$subtitleColor: #cc0099;
$darkGrey: #888b8d;
$headerGrow: #9C9C9C;
$placeHolderColor: #63666A;
$tableSelected: rgba(0, 176, 255, 0.14);
$popupBackground: rgba(255, 255, 255, 0.85);

// Global Font sizes
$smallSize: 14px;
$mediumSize: 16px;
$largeSize: 18px;
$bigTitleSize: 36px;
$subTitleSize: 20px;
$mediumTitleSize: 32px;
$pageTitleSize: 44px;

//shadows
$material-header-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);

//Mixins and functions
@function filter($property, $prefix ) {
  @return ('-' + $prefix + '-' + $property);
}

@mixin prefixer($property, $value){
  #{filter($property, webkit)} : $value;
}

// Screen breakpoints
$breakPoint-sm: 600px;
$breakPoint-md: 960px;
$breakPoint-lg: 1280px;
$breakPoint-xl: 1920px;
