body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App {
  text-align: center;
} */

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "J&J Circular";
  list-style: none;
  text-decoration: none; }

.margin-top-0 {
  margin-top: 0px !important; }

.padding-top-0 {
  padding-top: 0px !important; }

.mtop-0 {
  margin-top: 0px !important; }

.ptop-0 {
  padding-top: 0px !important; }

.margin-bottom-0 {
  margin-bottom: 0px !important; }

.padding-bottom-0 {
  padding-bottom: 0px !important; }

.mbottom-0 {
  margin-bottom: 0px !important; }

.pbottom-0 {
  padding-bottom: 0px !important; }

.margin-left-0 {
  margin-left: 0px !important; }

.padding-left-0 {
  padding-left: 0px !important; }

.mleft-0 {
  margin-left: 0px !important; }

.pleft-0 {
  padding-left: 0px !important; }

.margin-right-0 {
  margin-right: 0px !important; }

.padding-right-0 {
  padding-right: 0px !important; }

.mright-0 {
  margin-right: 0px !important; }

.pright-0 {
  padding-right: 0px !important; }

.margin-0 {
  margin: 0px !important; }

.padding-0 {
  padding: 0px !important; }

.m-0 {
  margin: 0px !important; }

.p-0 {
  padding: 0px !important; }

.margin-top-10 {
  margin-top: 10px !important; }

.padding-top-10 {
  padding-top: 10px !important; }

.mtop-10 {
  margin-top: 10px !important; }

.ptop-10 {
  padding-top: 10px !important; }

.margin-bottom-10 {
  margin-bottom: 10px !important; }

.padding-bottom-10 {
  padding-bottom: 10px !important; }

.mbottom-10 {
  margin-bottom: 10px !important; }

.pbottom-10 {
  padding-bottom: 10px !important; }

.margin-left-10 {
  margin-left: 10px !important; }

.padding-left-10 {
  padding-left: 10px !important; }

.mleft-10 {
  margin-left: 10px !important; }

.pleft-10 {
  padding-left: 10px !important; }

.margin-right-10 {
  margin-right: 10px !important; }

.padding-right-10 {
  padding-right: 10px !important; }

.mright-10 {
  margin-right: 10px !important; }

.pright-10 {
  padding-right: 10px !important; }

.margin-10 {
  margin: 10px !important; }

.padding-10 {
  padding: 10px !important; }

.m-10 {
  margin: 10px !important; }

.p-10 {
  padding: 10px !important; }

.margin-top-15 {
  margin-top: 15px !important; }

.padding-top-15 {
  padding-top: 15px !important; }

.mtop-15 {
  margin-top: 15px !important; }

.ptop-15 {
  padding-top: 15px !important; }

.margin-bottom-15 {
  margin-bottom: 15px !important; }

.padding-bottom-15 {
  padding-bottom: 15px !important; }

.mbottom-15 {
  margin-bottom: 15px !important; }

.pbottom-15 {
  padding-bottom: 15px !important; }

.margin-left-15 {
  margin-left: 15px !important; }

.padding-left-15 {
  padding-left: 15px !important; }

.mleft-15 {
  margin-left: 15px !important; }

.pleft-15 {
  padding-left: 15px !important; }

.margin-right-15 {
  margin-right: 15px !important; }

.padding-right-15 {
  padding-right: 15px !important; }

.mright-15 {
  margin-right: 15px !important; }

.pright-15 {
  padding-right: 15px !important; }

.margin-15 {
  margin: 15px !important; }

.padding-15 {
  padding: 15px !important; }

.m-15 {
  margin: 15px !important; }

.p-15 {
  padding: 15px !important; }

.margin-top-20 {
  margin-top: 20px !important; }

.padding-top-20 {
  padding-top: 20px !important; }

.mtop-20 {
  margin-top: 20px !important; }

.ptop-20 {
  padding-top: 20px !important; }

.margin-bottom-20 {
  margin-bottom: 20px !important; }

.padding-bottom-20 {
  padding-bottom: 20px !important; }

.mbottom-20 {
  margin-bottom: 20px !important; }

.pbottom-20 {
  padding-bottom: 20px !important; }

.margin-left-20 {
  margin-left: 20px !important; }

.padding-left-20 {
  padding-left: 20px !important; }

.mleft-20 {
  margin-left: 20px !important; }

.pleft-20 {
  padding-left: 20px !important; }

.margin-right-20 {
  margin-right: 20px !important; }

.padding-right-20 {
  padding-right: 20px !important; }

.mright-20 {
  margin-right: 20px !important; }

.pright-20 {
  padding-right: 20px !important; }

.margin-20 {
  margin: 20px !important; }

.padding-20 {
  padding: 20px !important; }

.m-20 {
  margin: 20px !important; }

.p-20 {
  padding: 20px !important; }

.margin-top-25 {
  margin-top: 25px !important; }

.padding-top-25 {
  padding-top: 25px !important; }

.mtop-25 {
  margin-top: 25px !important; }

.ptop-25 {
  padding-top: 25px !important; }

.margin-bottom-25 {
  margin-bottom: 25px !important; }

.padding-bottom-25 {
  padding-bottom: 25px !important; }

.mbottom-25 {
  margin-bottom: 25px !important; }

.pbottom-25 {
  padding-bottom: 25px !important; }

.margin-left-25 {
  margin-left: 25px !important; }

.padding-left-25 {
  padding-left: 25px !important; }

.mleft-25 {
  margin-left: 25px !important; }

.pleft-25 {
  padding-left: 25px !important; }

.margin-right-25 {
  margin-right: 25px !important; }

.padding-right-25 {
  padding-right: 25px !important; }

.mright-25 {
  margin-right: 25px !important; }

.pright-25 {
  padding-right: 25px !important; }

.margin-25 {
  margin: 25px !important; }

.padding-25 {
  padding: 25px !important; }

.m-25 {
  margin: 25px !important; }

.p-25 {
  padding: 25px !important; }

.margin-top-30 {
  margin-top: 30px !important; }

.padding-top-30 {
  padding-top: 30px !important; }

.mtop-30 {
  margin-top: 30px !important; }

.ptop-30 {
  padding-top: 30px !important; }

.margin-bottom-30 {
  margin-bottom: 30px !important; }

.padding-bottom-30 {
  padding-bottom: 30px !important; }

.mbottom-30 {
  margin-bottom: 30px !important; }

.pbottom-30 {
  padding-bottom: 30px !important; }

.margin-left-30 {
  margin-left: 30px !important; }

.padding-left-30 {
  padding-left: 30px !important; }

.mleft-30 {
  margin-left: 30px !important; }

.pleft-30 {
  padding-left: 30px !important; }

.margin-right-30 {
  margin-right: 30px !important; }

.padding-right-30 {
  padding-right: 30px !important; }

.mright-30 {
  margin-right: 30px !important; }

.pright-30 {
  padding-right: 30px !important; }

.margin-30 {
  margin: 30px !important; }

.padding-30 {
  padding: 30px !important; }

.m-30 {
  margin: 30px !important; }

.p-30 {
  padding: 30px !important; }

.margin-top-40 {
  margin-top: 40px !important; }

.padding-top-40 {
  padding-top: 40px !important; }

.mtop-40 {
  margin-top: 40px !important; }

.ptop-40 {
  padding-top: 40px !important; }

.margin-bottom-40 {
  margin-bottom: 40px !important; }

.padding-bottom-40 {
  padding-bottom: 40px !important; }

.mbottom-40 {
  margin-bottom: 40px !important; }

.pbottom-40 {
  padding-bottom: 40px !important; }

.margin-left-40 {
  margin-left: 40px !important; }

.padding-left-40 {
  padding-left: 40px !important; }

.mleft-40 {
  margin-left: 40px !important; }

.pleft-40 {
  padding-left: 40px !important; }

.margin-right-40 {
  margin-right: 40px !important; }

.padding-right-40 {
  padding-right: 40px !important; }

.mright-40 {
  margin-right: 40px !important; }

.pright-40 {
  padding-right: 40px !important; }

.margin-40 {
  margin: 40px !important; }

.padding-40 {
  padding: 40px !important; }

.m-40 {
  margin: 40px !important; }

.p-40 {
  padding: 40px !important; }

.margin-top-50 {
  margin-top: 50px !important; }

.padding-top-50 {
  padding-top: 50px !important; }

.mtop-50 {
  margin-top: 50px !important; }

.ptop-50 {
  padding-top: 50px !important; }

.margin-bottom-50 {
  margin-bottom: 50px !important; }

.padding-bottom-50 {
  padding-bottom: 50px !important; }

.mbottom-50 {
  margin-bottom: 50px !important; }

.pbottom-50 {
  padding-bottom: 50px !important; }

.margin-left-50 {
  margin-left: 50px !important; }

.padding-left-50 {
  padding-left: 50px !important; }

.mleft-50 {
  margin-left: 50px !important; }

.pleft-50 {
  padding-left: 50px !important; }

.margin-right-50 {
  margin-right: 50px !important; }

.padding-right-50 {
  padding-right: 50px !important; }

.mright-50 {
  margin-right: 50px !important; }

.pright-50 {
  padding-right: 50px !important; }

.margin-50 {
  margin: 50px !important; }

.padding-50 {
  padding: 50px !important; }

.m-50 {
  margin: 50px !important; }

.p-50 {
  padding: 50px !important; }

.margin-top-60 {
  margin-top: 60px !important; }

.padding-top-60 {
  padding-top: 60px !important; }

.mtop-60 {
  margin-top: 60px !important; }

.ptop-60 {
  padding-top: 60px !important; }

.margin-bottom-60 {
  margin-bottom: 60px !important; }

.padding-bottom-60 {
  padding-bottom: 60px !important; }

.mbottom-60 {
  margin-bottom: 60px !important; }

.pbottom-60 {
  padding-bottom: 60px !important; }

.margin-left-60 {
  margin-left: 60px !important; }

.padding-left-60 {
  padding-left: 60px !important; }

.mleft-60 {
  margin-left: 60px !important; }

.pleft-60 {
  padding-left: 60px !important; }

.margin-right-60 {
  margin-right: 60px !important; }

.padding-right-60 {
  padding-right: 60px !important; }

.mright-60 {
  margin-right: 60px !important; }

.pright-60 {
  padding-right: 60px !important; }

.margin-60 {
  margin: 60px !important; }

.padding-60 {
  padding: 60px !important; }

.m-60 {
  margin: 60px !important; }

.p-60 {
  padding: 60px !important; }

.margin-top-70 {
  margin-top: 70px !important; }

.padding-top-70 {
  padding-top: 70px !important; }

.mtop-70 {
  margin-top: 70px !important; }

.ptop-70 {
  padding-top: 70px !important; }

.margin-bottom-70 {
  margin-bottom: 70px !important; }

.padding-bottom-70 {
  padding-bottom: 70px !important; }

.mbottom-70 {
  margin-bottom: 70px !important; }

.pbottom-70 {
  padding-bottom: 70px !important; }

.margin-left-70 {
  margin-left: 70px !important; }

.padding-left-70 {
  padding-left: 70px !important; }

.mleft-70 {
  margin-left: 70px !important; }

.pleft-70 {
  padding-left: 70px !important; }

.margin-right-70 {
  margin-right: 70px !important; }

.padding-right-70 {
  padding-right: 70px !important; }

.mright-70 {
  margin-right: 70px !important; }

.pright-70 {
  padding-right: 70px !important; }

.margin-70 {
  margin: 70px !important; }

.padding-70 {
  padding: 70px !important; }

.m-70 {
  margin: 70px !important; }

.p-70 {
  padding: 70px !important; }

.margin-top-80 {
  margin-top: 80px !important; }

.padding-top-80 {
  padding-top: 80px !important; }

.mtop-80 {
  margin-top: 80px !important; }

.ptop-80 {
  padding-top: 80px !important; }

.margin-bottom-80 {
  margin-bottom: 80px !important; }

.padding-bottom-80 {
  padding-bottom: 80px !important; }

.mbottom-80 {
  margin-bottom: 80px !important; }

.pbottom-80 {
  padding-bottom: 80px !important; }

.margin-left-80 {
  margin-left: 80px !important; }

.padding-left-80 {
  padding-left: 80px !important; }

.mleft-80 {
  margin-left: 80px !important; }

.pleft-80 {
  padding-left: 80px !important; }

.margin-right-80 {
  margin-right: 80px !important; }

.padding-right-80 {
  padding-right: 80px !important; }

.mright-80 {
  margin-right: 80px !important; }

.pright-80 {
  padding-right: 80px !important; }

.margin-80 {
  margin: 80px !important; }

.padding-80 {
  padding: 80px !important; }

.m-80 {
  margin: 80px !important; }

.p-80 {
  padding: 80px !important; }

.mtop-auto {
  margin-top: auto !important; }

.ptop-auto {
  padding-top: auto !important; }

.mbottom-auto {
  margin-bottom: auto !important; }

.pbottom-auto {
  padding-bottom: auto !important; }

.mleft-auto {
  margin-left: auto !important; }

.pleft-auto {
  padding-left: auto !important; }

.mright-auto {
  margin-right: auto !important; }

.pright-auto {
  padding-right: auto !important; }

.m-auto {
  margin: auto !important; }

.p-auto {
  padding: auto !important; }

.font-size-20 {
  font-size: 20px; }

.flex {
  display: flex; }
  .flex > .flex-grow {
    flex-grow: 1; }

.justify-center {
  justify-content: center; }

.justify-space-between {
  justify-content: space-between; }

.weight-black {
  font-weight: 900; }

a {
  color: #000099; }

hr {
  border: 0;
  border-bottom: 1px solid #D8D8D8; }

ul.list li {
  margin-bottom: 15px;
  line-height: 26px;
  font-weight: 300;
  display: flex; }
  ul.list li::before {
    color: #CA001B;
    content: "\2022";
    font-size: 1em;
    padding-right: 10px; }

ol.list {
  counter-reset: item; }
  ol.list li {
    margin-bottom: 15px;
    line-height: 26px;
    font-weight: 300;
    display: flex; }
    ol.list li::before {
      content: counter(item) ". ";
      counter-increment: item;
      color: #CA001B;
      font-weight: bold;
      padding-right: 10px; }

@media screen and (max-width: 790px) {
  button[aria-selected="true"] {
    border-bottom: none !important; } }

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

#content {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column; }
  #content > * {
    flex-grow: 1;
    flex-shrink: 1; }

