@import "_main.scss";

$spaces: (0, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, auto);
$font-size: (20);
$sides: (top, bottom, left, right, all);

* {
  margin: 0;
  padding: 0;
  font-family: "J&J Circular";
  list-style: none;
  text-decoration: none;
}

@each $space in $spaces {
  @each $side in $sides {
    @if $space == 'auto'{
      @if $side == 'all' {
        .m-#{$space} {
          margin: #{$space} !important;
        }

        .p-#{$space} {
          padding: #{$space} !important;
        }
      } @else {
        .m#{$side}-#{$space} {
            margin-#{$side}: #{$space} !important;
        }

        .p#{$side}-#{$space} {
            padding-#{$side}: #{$space} !important;
        }
      }
  } @else {
      @if $side == 'all' {
        .margin-#{$space} {
          margin: #{$space}px !important;
        }

        .padding-#{$space} {
          padding: #{$space}px !important;
        }

        .m-#{$space} {
          margin: #{$space}px !important;
        }

        .p-#{$space} {
          padding: #{$space}px !important;
        }
      } @else {
        .margin-#{$side}-#{$space} {
          margin-#{$side}: #{$space}px !important;
        }

        .padding-#{$side}-#{$space} {
          padding-#{$side}: #{$space}px !important;
        }

        .m#{$side}-#{$space} {
          margin-#{$side}: #{$space}px !important;
        }

        .p#{$side}-#{$space} {
          padding-#{$side}: #{$space}px !important;
        }
      }
    }
  }
}

@each $size in $font-size {
  .font-size-#{$size} {
    font-size: #{$size}px;
  }
}

.flex {
  display: flex;

  & > .flex-grow {
    flex-grow: 1;
  }
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.weight-black {
  font-weight: 900;
}

a {
  color: $blueText;
}

hr {
  border: 0;
  border-bottom: 1px solid #D8D8D8;
}

ul.list {
  li {
    margin-bottom: 15px;
    line-height: 26px;
    font-weight: 300;
    display: flex;
    &::before {
      color: #CA001B;
      content: "\2022";
      font-size: 1em;
      padding-right: 10px;
    }
  }
}

ol.list {
  counter-reset: item;
  li {
    margin-bottom: 15px;
    line-height: 26px;
    font-weight: 300;
    display: flex;
    &::before {
      content: counter(item) ". ";
      counter-increment: item;
      color: #CA001B;
      font-weight: bold;
      padding-right: 10px;
    }
  }
}
@media screen and (max-width: 790px) {
  button[aria-selected="true"]{
      border-bottom: none !important;
  }
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#content {
  flex-grow : 1;
  flex-shrink : 1;
  display: flex;
  flex-direction: column;
  & > * {
    flex-grow : 1;
    flex-shrink : 1;
  }
}
